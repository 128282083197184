
.details{
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: 3;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .box__container{
        background-color: #fff;
        width: 65%;
        height: 85%;
        justify-content: center;
        display: inline;
        position: relative;
        align-items: center;
        border-radius: 1rem;
        padding: 2rem 1rem;
        overflow-y: auto;
        overflow-x: hidden;

        .d__close{
            position: absolute;
            right: 0;
            top: 0;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            float: right;
            background-color: rgba(86, 72, 72, 0.5);
            color: #fff;
            margin: 1rem;
            font-family: var(--font-base);
            font-weight: 800;
            cursor: pointer;
            transition: all 0.3s ease;
    
            svg{
                width: 50%;
                height: 50%;
                color: var(--white-color);
            }
        }
        .d__main-content{
            width: 100%;
    
    
            h1{
                font-size: 4rem;
                text-transform: uppercase;
                font-weight: 600;
                font-family: Tungsten;
    
                @media screen and (max-width: 1200px) {
                    font-size: 2rem;
                    // font-weight: 400;
                }
    
            }

            .d__name-tech{
                margin: 1rem 0rem;
                font-size: 3rem;
                text-transform: uppercase;
                font-weight: 600;
                font-family: Tungsten;
                letter-spacing: 1px;
    
                @media screen and (max-width: 1200px) {
                    font-size: 1.5rem;
                }
    
            }
    
            .d__image-container{
                position: relative;
                width: 100%;

                @media screen and (min-width: 750px){
                    display: none;
                }
            }
            .d__link-btn{
                position: absolute;
                margin: 5px;
                right: 30px;
                
                button{
                    font-size: 16px;
                    border: none;
                    cursor: pointer;
                    border-radius: 5px;
                    padding: 5px 25px;
                    margin: 5px;
                    background-color:#fff;
                    color: var(--black-color);
                    justify-content: center;
                    align-items: center;
                }
                @media screen and (min-width: 750px){
                    display: none;
                }
            }
            .d__head-img{
                width: 100%;
                display: flex;
                flex-direction: column;
    
                img{
                    width: 100%;
                    height: 250px;
                    border-radius: 15px;
                    object-fit: cover;
                    margin-bottom: 20px;
                }
                @media screen and (max-width: 1200px) {
                    img{
                        height: 320px;
                }
            
                 }
            }

            .d__descriprtion{
                margin-top: 30px;
                font-size: 20px;
                font-family: var(--secondary-base);
                text-indent: 0.7rem;
                font-weight: 400;
                color: var(--black-color);
                text-align: justify;

                @media screen and (max-width: 450px) {
                    font-size: 16px;
                }
            }
            
        }

        @media screen and (max-width: 900px) {
            padding: 1rem 1.5rem;
            width: 100%;
            height: 100%;
            border-radius: 0;
        }
    }
    .carousel{
        cursor: pointer;
        // overflow: hidden;
    }
    .card-top{
        height: 200px;
        width: 330px;
        padding: 20px;

        img{
            width: 100%;
            height: 100%;
            border-radius: 1rem;
            object-fit: cover;
            // pointer-events:all;
            
        }
        @media screen and (max-width: 450px) {
            width: 100%;
        }
    }

}
.slick-slide > div {
    margin: 0 10px;;
}

.slick-list {
    margin: 0 -10px;
}

.d__detail-list{
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 5rem;

    @media screen and (max-width: 900px) {
        margin-right: 0;
        justify-content: center;
        align-items: center;
    }
}

.d__detail-item{
    flex-direction: column;
    text-align: center;
    margin: 1rem;

    transition: all 0.3s ease-in-out;

    div{
        justify-content: center;
        align-items: center;
        display: flex;
        width: 90px;
        height: 90px;
        border-radius: 50%;
        background-color:  #e4e4e4;

        .d__detail-icon{
            width: 50%;
            height: 50%;
        }
        &:hover {
            box-shadow: 0 0 25px #fef4f5;
        }

        @media screen and (min-width: 2000px) {
            width: 150px;
            height: 150px;
        }

        @media screen and (max-width: 900px) {
            width: 70px;
            height: 70px;
        }
    }
    p{
        font-weight: 600;
        margin-top: 0.5rem;
    }
    @media screen and (min-width: 2000px) {
        margin: 1rem 2rem;

        p{
            margin-top: 1rem;
        }
    }
}
