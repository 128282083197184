.app {
    background-color: var(--primary-color);
    font-family: var(--font-base);
  }
  
  .app__whitebg {
    background-color: var(--white-color);
  }
  
  .app__bluebg{
    background-color: #141723;
  }
  .app__primarybg {
    background-color: var(--primary-color);
  }
  
  @font-face{
    font-family: Tungsten;
    src: url(./font/Tungsten-Bold.ttf);
  }
  .app__container {
    width: 100%;
    min-height: 100vh;
  
    display: flex;
    flex-direction: row;
  }
  
  .app__flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .app__wrapper {
    flex: 1;
    width: 100%;
    flex-direction: column;
    padding: 3rem 2rem;
  
    @media screen and (max-width: 450px) {
      padding: 4rem 1rem 2rem;
    }
  }
  
  
  .copyright {
    width: 100%;
    padding: 2rem 0 0;
  
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  
    p {
      text-transform: uppercase;
      color: var(--black-color);
    }
  }
  
  .head-text {
    font-size: 2.75rem;
    font-weight: 800;
    text-align: center;
    color: var(--black-color);
    text-transform: capitalize;
  
    span {
      color: var(--secondary-color);
    }
  
    @media screen and (min-width: 2000px) {
      font-size: 4rem;
    }
  
    @media screen and (max-width: 450px) {
      font-size: 2rem;
    }
  }

  .wrap-text {
    font-size: 0.8rem;
    text-align: left;
    color: rgb(162, 162, 162);
  
    @media screen and (min-width: 2000px) {
      font-size: 1.75rem;
    }
  }
  
  .p-text {
    font-size: 0.8rem;
    text-align: left;
    color: var(--gray-color);
   
  
    @media screen and (min-width: 2000px) {
      font-size: 1.75rem;
    }
  }
  
  .bold-text {
    font-size: 1rem;
    font-weight: 800;
    color: black;
    text-align: left;
    letter-spacing: 1.3px;
  
    @media screen and (min-width: 2000px) {
      font-size: 2rem;
    }
  
    @media screen and (max-width: 450px) {
      font-size: 0.9rem;
    }
  }
  
  .app__social {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
  
    padding: 1rem;
  
    a {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: var(--white-color);
      margin: 0.25rem 0;
      border: 1px solid var(--lightGray-color);
  
      display: flex;
      justify-content: center;
      align-items: center;
  
      transition: all 0.3s ease-in-out;
  
      svg {
        width: 15px;
        height: 15px;
        color: var(--gray-color);
      }
  
      &:hover {
        background-color: #141723;
        border-color: #141723;
  
        svg {
          color: var(--white-color);
        }
      }
  
      @media screen and (min-width: 2000px) {
        width: 70px;
        height: 70px;
  
        margin: 0.5rem 0;
  
        svg {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
  
  .app__navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  
    padding: 1rem;
  
    .app__navigation-dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #cbcbcb;
      margin: 0.5rem;
  
      transition: background-color 0.2s ease-in-out;
  
      &:hover {
        background-color: var(--gray-color);
      }
  
      @media screen and (min-width: 2000px) {
        width: 20px;
        height: 20px;
      }
    }
  }
  
  @media screen and (max-width: 500px) {
    .app__navigation,
    .app__social {
      display: none;
    }
  
    .copyright {
      padding: 2rem;
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(27, 27, 27, 0.541);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    display: flex;
    align-items: center;
  }
  .overlay img {
    display: block;
    max-width: 60%;
    max-height: 80%;
    margin: 60px auto;
    box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  }
  .overlay > span {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 30px;
    color: #ffffff;
    z-index: 999;
    cursor: pointer;
  }
  .overlay-arrows_left {
    display: flex;
    color: #ffffff;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    width: 50px;
    height: 50px;
    left: 0;
    z-index: 999;
  }
  
  .overlay-arrows_left svg {
    width: 50px;
    height: 50px;
  }
  
  .overlay-arrows_right svg {
    width: 50px;
    height: 50px;
  }
  
  .overlay-arrows_right {
    display: flex;
    color: #ffffff;
    justify-content: space-between;
    position: absolute;
    right: 0;
    width: 50px;
    height: 50px;
    top: 50%;
    z-index: 999;
  }