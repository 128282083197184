#home{
    position: relative;
    background: url('../../assets/bgIMG.png');
    background-size: cover;
    background-repeat: repeat;
    background-position: center;

    .app__wrapper{
        padding: 0;
    }
        .copyright {
            display: none;
        }
}

.app__header{
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: row;
    padding: 6rem 2rem 0rem;

    @media screen and (min-width: 2000px) {
        padding-top: 8rem;
    }
    @media screen and (max-width: 1200px) {
        flex-direction: column;
    }
    @media screen and (max-width: 450px) {
        padding: 4rem 1rem 2rem;
    }
}

.app__header-info{
    flex: 0.65;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;

    @media screen and (max-width: 2000px) {
      width: 100%;
      margin-right: 0rem;  
    }
    @media screen and (max-width: 450px) {
        display: flex;
        justify-content: center;
        text-align: center;
    }
}

.app__header-badge{
    width: 100%;
    position: relative;
    margin-top: 6rem;

    .h__me{
        width: 100%;
        display: flex;
        flex-direction: column;

        .h__hello{
            font-size: 2rem;
            color: var(--gray-color);
            font-family: Tungsten;
            font-weight: 600;
        }
        }

        .h__name{
            font-size: 9rem;
            font-weight: 800;
            line-height: 1;
            margin: 0;
            padding: 0;
            font-family: Tungsten;
            color: #0f1923;
            text-transform: uppercase;
        }
    }
    .h__dev{
        width: 100%;
        display: flex;
        flex-direction: column;
        font-size: 20px;
        margin-top: 20px;
        margin-bottom: 50px;
        @media screen and (max-width: 450px) {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            }
        p{
            font-size: 20px;
            font-weight: 400;
            color: #383e3a;;
            text-transform: uppercase;
            font-family: Tungsten;
        }
    }

    // .badge-cmp, .tag-cmp{
    //     padding: 1rem 2rem;
    //     border: var(--white-color);
    //     border-radius: 15px;
    //     flex-direction: row;
    //     width: auto;
    //     box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    //     font-family: Tungsten-Bold,arial,georgia,sans-serif;
    //     color: #0f1923;
    // }

    // .tag-cmp{
    //     flex-direction: column;
    //     margin-top: 3rem;

    //     p{
    //         width: 100%;
    //         text-transform: uppercase;
    //         text-align: right;
    //     }
    // }

    .h__button{
        all: unset;
        position: relative;
        cursor: pointer;

        &::after{
            content: '';
            position: absolute;
            left: -11px;
            top: -10px;
            width: calc(100% + 20px);
            height: calc(50% + 5px);
            border-top: 1px solid rgba(97, 97, 97, 0.5);
            border-left: 1px solid rgba(97, 97, 97, 0.5);
            border-right: 1px solid rgba(97, 97, 97, 0.5);
            // border-top: 1px solid rgba(255, 255, 255, 0.5);
            // border-left: 1px solid rgba(255, 255, 255, 0.5);
            // border-right: 1px solid rgba(255, 255, 255, 0.5);
        }

        &::before{
            content: '';
            position: absolute;
            left: -11px;
            bottom: -7px;
            width: calc(100% + 20px);
            height: calc(50% + 5px);
            border-bottom: 1px solid rgba(97, 97, 97, 0.5);
            border-left: 1px solid rgba(97, 97, 97, 0.5);
            border-right: 1px solid rgba(97, 97, 97, 0.5);
        }

        div{
            position: relative;
            display: inline-block;
            overflow: hidden;
            width: auto;
            height: auto;
            padding: 15px 0;
            background: var(--gray-color);

            &::after{
                content: '';
                position: absolute;
                right: 0;
                bottom: 0;
                width: 5px;
                height: 5px;
                background: black;
                transition: 0.4s;
            }
            
            span{
                position: absolute;
                width: 120%;
                height: 110%;
                transform: translate(-100%, -20px);
                background: black;
                clip-path: polygon(5% 0, 100% 0, 95% 100%, 0% 100%);
                transition: 0.4s;
            }


            a{
                position: relative;
                text-decoration: none;
                color: #ffff;
                font-family: sans-serif;
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 800;
                padding: 20px 80px;
            }
            
        }

    
    }
    .h__button:hover div span{
        transform: translate(-10%, -20px);
    }
    .h__button:hover div::after{
        background-color: #ffff;
    }


    // .tag-cv{
    //     margin-top: 3rem;
    //     cursor: pointer;
    //     display: inline-block;
        
    //     &:hover{
    //         transition: all ease 0.3s;
    //         background-color: var(--secondary-color);
    //         border: none;
    //         color: #ffff;
    //     }
    //     a{
    //         text-decoration: none;
    //         width: max-content;
    //         color: black;
    //         &:hover{
    //             color: #ffff;
    //         }
    //     }
    // }
    span{
        font-size: 2.5rem;

        @media screen and (min-width: 2000px) {
            font-size: 5rem;
        }
    }

//     @media screen and (max-width: 1200px) {
//         margin-top: 3rem;
//         justify-content: flex-start;
//         align-items: flex-start;
//     }
// }

.app__header-circles{
    flex: 0.75;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 100%;
    margin-left: 1rem;

    div{
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background: var(--white-color);
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

        img{
            width: 60%;
            height: 60%;
        }
    }

    div:nth-child(1){
        width: 100px;
        height: 100px;
    }
    div:nth-child(2){
        margin: 1.75rem;
        width: 150px;
        height: 150px;
    }
    div:nth-child(3){
        width: 70px;
        height: 70px;
    }

    @media screen and (min-width: 2000px) {
        div:nth-child(1){
            width: 400px;
            height: 400px;
        }
        div:nth-child(2){
            width: 150px;
            height: 150px;
        }
        div:nth-child(3){
            width: 200px;
            height: 200px;
        }
    }
    @media screen and (max-width: 1200px) {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 0;

        div{
            margin: 1rem;
        }
    }

}

.app__header-img{
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;

    img{
        width: 100%;
        object-fit: contain;
        z-index: 1;
    }
    .overlay_circle{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        width: 100%;
        height: 90%;
    }

    @media screen and (max-width: 1200px) {
        margin: 2rem 0;
    }
}