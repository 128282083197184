.app__works{
    flex: 1;
    width: 100%;
    flex-direction: column;
}

.head-content{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h5{
        font-size: 2rem;
        font-family: Tungsten;
        text-transform: uppercase;
        color: gray;
    }
    .head-txt{
        text-transform: uppercase;
        color: var(--black-color);
        font-size: 7rem;
        font-family: Tungsten;
        font-weight: 700;
        @media screen and (max-width: 680px) {
            text-align: center;
            font-size: 6rem;
            letter-spacing: 1.5px;
        }
        @media screen and (max-width: 480px) {
            text-align: center;
            font-size: 4rem;
        }
    }
}

.app__work-filter{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: 4rem 0 2rem;
    @media screen and (max-width: 680px) {
        justify-content: center;
    }
    

    .app__work-filter-item {
        padding: 1.2rem 2rem;
        border-radius: 0.5rem;
        background-color: #fff;
        color: #000;
        font-weight: 800;
        cursor: pointer;
        transition: all 0.3s ease;
        margin: 0.5rem;

        // &:hover{
        //     background: #141723;
        //     color: #fff;
        // }


        @media screen and (min-width: 2000px) {
            padding: 1rem 2rem;
            border-radius: 0.85rem;
        }
        @media screen and (max-width: 600px) {
            padding: 0.7rem 1rem;
            border-radius: 0.85rem;
        }
    }

    .item-active {
        background: #141723;
        color: #fff;
    }

}


.app__work-portfolio{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .app__work-item{
        width: 270px;
        flex-direction: column;
        margin: 2rem;
        padding: 1rem;
        border-radius: 0.5rem;
        background-color: #fff;
        color: #000;
        transition: all 0.3s ease;
        cursor: pointer;

        &:hover {
            box-shadow: 0 0 25px rgba(0,0,0,0.2);
        }

        @media screen and (min-width: 2000px) {
            width: 470px;
            padding: 1.25rem;
            border-radius: 0.7rem;
        }
        @media screen and (max-width: 300px) {
            width: 100%;
            margin: 1rem;

        }
    }
}

.app__work-img{
    width: 100%;
    height: 230px;
    position: relative;

    img{
        width: 100%;
        height: 100%;
        border-radius: 0.5rem;
        object-fit: cover;
    }

    @media screen and (min-width: 2000px) {
        height: 350px;
    }
}
.w__link{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    text-decoration: none;
}
    .mobile_btn{
        width: 100%;
        text-decoration: none;
        padding: 0.8rem 2rem;
        border-radius: 10px;
        border: none;
        background-color: #141723;
        font-weight: 500;
        color: var(--white-color);
        margin: 5px;
        outline: none;
        font-family: var(--secondary-base);
        cursor: pointer;
        @media screen and (min-width: 1200px) {
            display: none;
        }
    }
   



.app__work-hover{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    border-radius: 0.5rem;
    opacity: 0;
    transition: all 0.3s ease;

    div{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: rgba(0,0,0,0.5);
        color: #fff;
        margin: 1rem;
        font-family: var(--font-base);
        font-weight: 800;
        cursor: pointer;
        transition: all 0.3s ease;

        svg{
            width: 50%;
            height: 50%;
            color: var(--white-color);
        }
    }
     @media screen and (max-width: 1200px) {
        display: none;
    }
}

.app__work-content{
    padding: 0.5rem;
    width: 100%;
    position: relative;
    flex-direction: column;

    h4{
        margin-top: 1rem;
        line-height: 1.5;
    }

    .app__work-tag{
        position: absolute;
        padding: 0.5rem 1rem;
        border-radius: 10px;
        background-color: #fff;
        top: -25px;
    }
}