.a__box-container{
    width: 100%;
    height: 100%;
    margin-top: 3rem;
}

.a__head-text{
    font-family: Tungsten;
    font-size: 5rem;
    text-align: center;
    justify-content: center;
    text-transform: uppercase;
    align-items: center;
    color: #fff;

    @media screen and (max-width: 680px) {
        font-size: 3.5rem;
    }
    @media screen and (max-width: 480px) {
        font-size: 2.5rem;
        letter-spacing: 1.5px;
    }
}
.a__author-txt{
    font-size: 1.5rem;
    font-family:'Barlow Condensed', sans-serif;
    text-transform: uppercase;
    line-height: 1.5;
    letter-spacing: 1.3px;
    font-weight: 400;
    color: rgba(255,255,255,.7);
    
    @media screen and (max-width: 680px) {
        font-size: 1rem;
    }
    @media screen and (max-width: 480px) {
        font-size: .8rem;
    }
}
.a__main-container{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    
    .a__context{
        // width: 100%;
        display: flex;
        flex-direction: column;
        padding: 2rem 1rem;
        h1{
            font-size: 3rem;
            font-family: Tungsten;
            text-transform: uppercase;
            color: #fff;
            width: 100%;
            
            @media screen and (max-width: 600px) {
                justify-content: center;
                width: 100%;
                display: flex;
            }
        }
        p{
            padding: 1rem 0rem;
            font-family: 'Barlow Condensed', sans-serif;
            font-weight: 200;
            color: rgba(255,255,255,.7);
            text-align: justify;
            text-indent: 20px;
            font-size: 18px;
        }
    }

    .a__container{
        width: 50%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin: 2rem;

        @media screen and (max-width: 600px) {
            width: 100%;
            justify-content: center;
            display: flex;
            align-items: center;
            margin: 0.5rem;
        }
        .a__background{
            background-color:rgba(255,255,255,.7);
            width: 250px;
            height: 250px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        img{
            width: 220px;
            height: 220px;
            border-radius: 50%;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
            object-fit: cover;
    
        }

    }
  

   
    @media screen and (min-width: 2000px) {
        img{
            height: 320px;
        }
    }
    @media screen and (max-width: 800px) {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        img{
            height: 320px;
        }
        p{
            padding: 0.5rem, 0.3rem ;
        }
    }
}


.app__about{
    flex: 1;
    width: 100%;
    flex-direction: column;
}

.app__profiles{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    // margin-top: 2rem;
    cursor: pointer;

}

.app__profile-item{
    width: 190px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin: 2rem;

    img{
        width: 100%;
        height: 170px;
        border-radius: 15px;
        object-fit: cover;

    }

    .txt-title{
        font-family: 'Barlow Condensed', sans-serif;
        line-height: 1.5;
        letter-spacing: 1.3px;
        color: #fff;
    }

    .txt.description{
        font-family: 'Barlow Condensed', sans-serif;
        line-height: 1.5;
        letter-spacing: 1.3px;
    }

    button{
        all: unset;
        position: relative;
        cursor: pointer;
        margin-top: 30px;

        &::after{
            content: '';
            position: absolute;
            left: -11px;
            top: -10px;
            width: calc(100% + 20px);
            height: calc(45% + 5px);
            border-top: 1px solid rgba(97, 97, 97, 0.5);
            border-left: 1px solid rgba(97, 97, 97, 0.5);
            border-right: 1px solid rgba(97, 97, 97, 0.5);
        }

        &::before{
            content: '';
            position: absolute;
            left: -11px;
            bottom: -7px;
            width: calc(100% + 20px);
            height: calc(45% + 5px);
            border-bottom: 1px solid rgba(97, 97, 97, 0.5);
            border-left: 1px solid rgba(97, 97, 97, 0.5);
            border-right: 1px solid rgba(97, 97, 97, 0.5);
        }
        div{
            position: relative;
            display: inline-block;
            overflow: hidden;
            width: auto;
            height: auto;
            padding: 2px 0;
            background: #001E2B;

            &::after{
                content: '';
                position: absolute;
                right: 0;
                bottom: 0;
                width: 5px;
                height: 5px;
                background: black;
                transition: 0.4s;
            }
            
            span{
                position: absolute;
                width: 120%;
                height: 150%;
                transform: translate(-100%, -20px);
                background: black;
                clip-path: polygon(5% 0, 100% 0, 95% 100%, 0% 100%);
                transition: 0.4s;
            }


            p{
                position: relative;
                text-decoration: none;
                color: #ffff;
                font-family: sans-serif;
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 800;
                padding: 10px 40px;

                @media screen and (max-width: 500px){
                    padding: 10px 47px;
                }
            }
            
        }
    }
    button:hover div span{
        transform: translate(-10%, -20px);
    }
    button:hover div::after{
        background-color: #ffff;
    }

    @media screen and (min-width: 2000px) {
        width: 370px;
        margin: 2rem 4rem;
        img{
            height: 320px;
        }
    }
}
