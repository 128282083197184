.app__skills{
    flex: 1;
    width: 100%;
    flex-direction: column;
}
.app__skills-container{
    width: 80%;
    margin-top: 3rem;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 900px) {
        width: 100%;
        flex-direction: column;
    }
}
.head-txt{
    font-family: Tungsten;
    text-transform: uppercase;
    font-size: 7rem;
    color: #fff;

    @media screen and (max-width: 680px) {
        font-size: 6rem;
        text-align: center;
    }
    
    @media screen and (max-width: 480px) {
        font-size: 4rem;
        text-align: center;
        letter-spacing: 1.5px;
    }
}

.app__skills-list{
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 5rem;

    @media screen and (max-width: 900px) {
        margin-right: 0;
        justify-content: center;
        align-items: center;
    }
}

.app__skills-item{
    flex-direction: column;
    text-align: center;
    margin: 1rem;

    transition: all 0.3s ease-in-out;

    div{
        justify-content: center;
        align-items: center;
        display: flex;
        width: 90px;
        height: 90px;
        border-radius: 50%;
        background-color:  #e4e4e4;

        .app__skill-icon{
            width: 50%;
            height: 50%;
        }
        &:hover {
            box-shadow: 0 0 25px #fef4f5;
        }

        @media screen and (min-width: 2000px) {
            width: 150px;
            height: 150px;
        }

        @media screen and (max-width: 900px) {
            width: 70px;
            height: 70px;
        }
    }
    p{
        font-weight: 600;
        margin-top: 0.5rem;
    }
    @media screen and (min-width: 2000px) {
        margin: 1rem 2rem;

        p{
            margin-top: 1rem;
        }
    }
}

.app__skills-exp{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    @media screen and (max-width: 900px) {
      margin-top: 2rem;
    }
}

.app__skills-exp-item{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 1rem 0;
    @media screen and (max-width: 680px) {
       display: flex;
       justify-content: center;
       align-items: center;
      }
}

.app__skills-exp-works{
    flex: 1;

    .app__skills-exp{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 1rem;
        cursor: pointer;

        h4{
            font-weight: 500;
        }
        .s__exp{
            color: #fff;
        }

        p{
            font-weight: 400;
            color: #fff;
            margin-top: 5px;
        }
    }
}

.app__skills-exp-year{
    margin-right: 3rem;

    p{
        font-weight: 800;
        color: rgb(185, 185, 185);

     
    }

    @media screen and (max-width: 400px) {
        margin-right: 1rem;
        
      }
}