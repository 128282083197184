.s__main-container{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(4, 4, 4, 0.25);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
}

.s__box-container{
    position: fixed;
    border-radius: 2rem;
    width: 65%;
    height: 85%;
    background-color: #ffff;
    padding: 1.5rem;
    z-index: 3;
    overflow-y: auto;
    overflow-x: hidden;

    @media screen and (max-width: 900px) {
        padding: 1rem 1.5rem;
        width: 100%;
        height: 100%;
        border-radius: 0;
    }


    .s__main-content{
        width: 100%;


        h1{
            font-size: 4rem;
            text-transform: uppercase;
            font-weight: 600;
            font-family: Tungsten;

            @media screen and (max-width: 1200px) {
                font-size: 2rem;
                // font-weight: 400;
            }

        }
        h2{
            font-size: 3rem;
            text-transform: uppercase;
            font-weight: 600;
            font-family: Tungsten;
            letter-spacing: 1px;

            @media screen and (max-width: 1200px) {
                font-size: 1.5rem;
            }

        }

    
        .s__head-img{
            width: 100%;
            display: flex;
            flex-direction: column;

            img{
                width: 100%;
                height: 250px;
                border-radius: 15px;
                object-fit: cover;
                margin-bottom: 20px;
            }

            p{
                margin-top: 20px;
                margin-bottom: 20px;
                font-size: 20px;
                text-align: justify;
                font-family: var(--secondary-base);
                text-indent: 0.7rem;
                font-weight: 400;
                color: var(--black-color);

                @media screen and (max-width: 450px) {
                    font-size: 16px;
                }
            }
            
            @media screen and (max-width: 1200px) {
                img{
                    height: 320px;
            }
        
             }
        }
        .s__close{
            position: absolute;
            right: 0;
            top: 0;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            float: right;
            background-color: rgba(0,0,0,0.5);
            color: #fff;
            margin: 1rem;
            font-family: var(--font-base);
            font-weight: 800;
            cursor: pointer;
            transition: all 0.3s ease;

            svg{
                width: 50%;
                height: 50%;
                color: var(--white-color);
            }
        }
    }
}
.s__name{
    font-family: Tungsten;
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 400;
    letter-spacing: 1.3px;
    margin-top: 2.5rem;
    color: black;
}
.s__image-container{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 2rem;
    cursor: pointer;
}
.s__image-content{
    width: 190px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin: 2rem;
  

    img{
        width: 100%;
        height: 170px;
        border-radius: 15px;
        object-fit: cover;
    }

}

.s__fimage-container{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 2rem;
    cursor: pointer;
}
.s__fimage-content{
    width: 220px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin: 2rem;
  

    img{
        width: 100%;
        height: 300px;
        border-radius: 15px;
        object-fit: contain;
    }

}

.s__section-list{
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 5rem;

    @media screen and (max-width: 900px) {
        margin-right: 0;
        justify-content: center;
        align-items: center;
    }
}

.s__section-item{
    flex-direction: column;
    text-align: center;
    margin: 1rem;

    transition: all 0.3s ease-in-out;

    div{
        justify-content: center;
        align-items: center;
        display: flex;
        width: 90px;
        height: 90px;
        border-radius: 50%;
        background-color:  #e4e4e4;

        .s__section-icon{
            width: 50%;
            height: 50%;
        }
        &:hover {
            box-shadow: 0 0 25px #fef4f5;
        }

        @media screen and (min-width: 2000px) {
            width: 150px;
            height: 150px;
        }

        @media screen and (max-width: 900px) {
            width: 70px;
            height: 70px;
        }
    }
    p{
        font-weight: 600;
        margin-top: 0.5rem;
    }
    @media screen and (min-width: 2000px) {
        margin: 1rem 2rem;

        p{
            margin-top: 1rem;
        }
    }
}
